.MuiTableHead-root {
  display: table-row-group;
}
thead {
  display: table-row-group;
}

/* Center the loader */
#loader-container {
  width: 100%;
  height: 100%;
  left: 0px;
  background: #80808026;
  z-index: 99999;
  top: 0px;
  position: absolute;
}
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border-radius: 50%;
  border-top: 2px solid #cf9439;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*loader END*/
.error {
  color: red;
  font-size: 12px;
}
.Toastify__toast-body {
  font-size: 12px !important;
}

.tss-1akey0g-MUIDataTableHeadCell-data {
  font-size: 1rem !important;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
/* input[type=number] {
    -moz-appearance: textfield;
} */