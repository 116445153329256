.tss-1703dxm-MUIDataTableFilter-root{
    background-color:#c0c5cd !important;
    width: 300px;
    
}

.tss-oeo5eo-MUIDataTableToolbar-filterPaper{
    left: 1200px !important;
    width: 300px;
}
